<template>
  <div class="mall-wrapper">
    <div>
      <div class="_banner">
        <img :src="head_img" alt="">
      </div>
      <template>
        <div class="_divider">
          <van-divider :style="{ color: '#cbcbcb', borderColor: '#cbcbcb', padding: '0 16px', fontSize: '12px' }">共有
            {{ dataList.length + datalist2.length }} 项权益
          </van-divider>
        </div>
        <div v-if="currMallId==2">
          <div class="show_title"  ><span>小微企业专属贷款</span></div>
          <div class="_list">
            <div v-for="item of dataList">
              <img class="_pic" style="width: 100%" @click="onClickPageLink(item.url)" :src="item.img" alt="">
            </div>
          </div>
          <div class="show_title"  ><span>个人专属贷款</span></div>
          <div class="_list">
            <div v-for="item of datalist2">
              <img class="_pic" style="width: 100%" @click="onClickPageLink(item.url)" :src="item.img" alt="">
            </div>
          </div>
        </div>
        <div v-else>

          <div class="_list">
            <div v-for="item of dataList">
              <img class="_pic" style="width: 100%" @click="onClickPageLink(item.url)" :src="item.img" alt="">
            </div>
          </div>
        </div>

      </template>
      <div class="_bottom_fixed">
        <div class="_height"></div>
        <div class="_bottom_back">
          <span class="_back_home" @click="$router.push('/home')"></span>
          <span class="_back_prev" @click="$router.go(-1)"></span>
        </div>
      </div>
    </div>
    <div class="_right_bar_new_card" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/applyDebitCard.do')"  >
      <img src="http://pic.eaworks.cn/scbank/uploads/2023922/6294112.png" >
    </div>
  </div>
</template>

<script>
import page_links from "../mixin/page_links";
import wx from "weixin-js-sdk";

export default {
  components: {},
  mixins: [page_links],
  data() {
    return {
      dataList: [],
      datalist2: [],
      head_img: "",
      currMallId: 0
    };
  },

  created() {
    console.log("-> [index.vue] created");
  },

  mounted() {

    this.currMallId = Number(this.$route.query.id) || 0;
    this.getMallDetail();
    this.getJsInfo();
    //
  },

  methods: {
    async getJsInfo() {
      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code === 200) {
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ]; wx.config(data.res);
      }
      wx.ready(function () {
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });

      wx.error(function (res) {
        console.info("wx error", res);
      });
    },
    onClickItem(item) {
      if (item.jump_type == null || item.jump_type == "") {
        this.$router.push("/activity?id=" + item.id);
      }
    },
    getMallDetail() {
      if (this.currMallId == 0) {
        return true;
      }
      if (this.currMallId == 1) {
        //建设者-返乡优惠
        this.head_img = "http://pic.eaworks.cn/scbank/uploads/2022924/919670.png"
        this.dataList = [{
          'img': "http://pic.eaworks.cn/scbank/uploads/2022810/3514250.png",
          'url': "/show_img?id=131f8c0e-455a-4dec-830f-e56c3a1c8a82"
        },
          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/3944517.png",
            'url': "/show_img?id=19fb91e5-050b-4495-bd5a-19ad3830abc0"
          }]
      }
      if (this.currMallId == 2) {
        //建设者卡 首页-五项专属-金融服务
        this.head_img = "http://pic.eaworks.cn/scbank/uploads/2022924/1020054.png"
        this.datalist2 = [
          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/8849920.png",
            'url': "/show_img?id=5b50a46c-fbef-4ba4-a4d9-8f0882eb956d"
          },
        {
          'img': "http://pic.eaworks.cn/scbank/uploads/2022810/8346237.png",
          'url': "/show_img?id=900632c1-7d53-4771-8716-a1753d59ea0e"
        },
          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/7603860.png",
            'url': "/show_img?id=bca4fec1-93f8-47ee-ad58-b55195448f19"
          }
        ]
        this.dataList = [
          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/4108454.png",
            'url': "/show_img?id=903f9514-35fe-4b95-ac4a-f18c05dd8fcf"
          },

          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/7178527.png",
            'url': "/show_img?id=900632c1-7d53-4771-8716-a1753d59ea0e"
          },
          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/3811853.png",
            'url': "/show_img?id=3884da47-3c51-475d-a5dd-88b410686416"
          },
          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/4693169.png",
            'url': "/show_img?id=a4174519-f86f-4d25-b262-760e3d384425"
          },
          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/3831088.png",
            'url': "/show_img?id=aaa49260-2a65-4bcf-9d92-8452bc4dba8e"
          },
          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/3168197.png",
            'url': "/show_img?id=26b8e0de-15c2-4319-ac31-9aac332c9ab9"
          },
          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/1548077.png",
            'url': "/show_img?id=fdf93264-1281-41d4-bfb8-97db3ec9c327"
          },
          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/2922359.png",
            'url': "/show_img?id=3250859d-a36c-4bc0-a33c-a814a1e3c996"
          },
          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/8788472.png",
            'url': "/show_img?id=dcc1770c-8f6a-41ff-9058-190bb1ee27af"
          },
          {
            'img': "http://pic.eaworks.cn/scbank/uploads/2022810/8380877.png",
            'url': "/show_img?id=82964455-ff25-4057-849a-3fe8796f93d3"
          }]
      }
    }
  }
};
</script>

<style lang="less" scoped>
.mall-wrapper {
  background: #ebebeb;
  position: relative;

  ._banner {
    img {
      display: block;
      width: 100%;
    }
  }

  /* 商家列表 */

  ._list {
    padding: 0.1rem 0.4rem 0.2rem;
    min-height: 10rem;

    ._item {
      position: relative;
      margin-bottom: 0.4rem;
      background: #fff;
      padding: 0.2rem;
      border-radius: 0.2rem;
      box-shadow: 0 6px 12px 0 rgba(30, 38, 51, 0.12);

      img._pic {
        display: block;
        height: 2.1rem;
        margin-left: 0.2rem;
        width: 100%;
      }
    }
  }

  // 食，标题
  ._sub_title {
    font-size: 0.2rem;
    color: #ec7453;
    padding: 0.2rem 0.3rem 0.2rem 0.2rem;
    margin-top: 0.4rem;

    span {
      display: inline-block;
      line-height: 0.45rem;
      padding: 0 0.4rem 0 0.5rem;
      background: url("../status/img/mail_title_bg.png") no-repeat right center;
      background-size: auto 100%;
      font-size: 0.34rem;
      color: #595757;
      margin-right: 0.15rem;
    }

    &._sub_title2 {
      text-align: right;
      padding-left: 0;
      color: #888;
      text-decoration: underline;

      span {
        background: url("../status/img/mail_title_bg2.png") no-repeat right center;
        background-size: 70% auto;
        float: left;
        text-decoration: none;
      }
    }
  }

  ._house_list {
    position: relative;
    padding: 0.2rem 0.3rem;
    border-radius: 0.3rem;

    ._item {
      background: #fff;
      border-radius: 0.4rem;
      padding: 0.2rem;
      margin-bottom: 0.4rem;
      //box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.01);
      ._views {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
        border-radius: 0.3rem;
        text-align: center;

        img._pic {
          display: block;
          width: 100%;
          height: 5rem;
          border-radius: 0.3rem 0.3rem 0 0;
        }

        ._title {
          font-size: 0.45rem;
          line-height: 1.8em;
          color: #333;
        }

        ._dsc {
          font-size: 0.2rem;
          color: #888;
          padding-bottom: 0.4rem;
        }
      }

      ._foot {
        text-align: center;

        span.btn_h {
          display: inline-block;
          width: 2.9rem;
          padding: 0.1rem 0 0.3rem;
          line-height: 0.6rem;
          background: url("../status/img/btn_h1.png") no-repeat right center;
          background-size: 100% auto;
          text-align: center;
          color: #fff;
          font-size: 0.3rem;
        }
      }
    }

    &._house_list2 {
      ._item {
        margin-bottom: 0.3rem;

        ._views {
          img._pic {
            height: 4.0rem;
          }

          ._title {
            font-size: 0.4rem;
            line-height: 2em;
          }
        }

        ._foot {
          margin: -0.1rem auto;

          span.btn_h {
            width: 2.4rem;
            padding: 0.15rem 0 0.3rem;
            line-height: 0.5rem;
            background: url("../status/img/btn_h2.png") no-repeat right center;
            background-size: 100% auto;
          }
        }
      }
    }
  }

  ._food_list {
    position: relative;
    padding: 0.2rem 0.4rem;

    ._item {
      margin: 0 auto 0.3rem;
      background: #fff;
      border-radius: 0.3rem;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);

      img._pic {
        display: block;
        border-radius: 0.3rem;
        width: 100%;
      }
    }
  }

  ._sale_list {
    img {
      display: block;
      width: 100%;
    }
  }

  ._vip_mail_list {
    position: relative;
    padding: 0.2rem 0.4rem;

    ._item {
      background: #fff;
      border-radius: 0.3rem;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);

      img._pic {
        display: block;
        border-radius: 0.3rem;
        width: 100%;
      }
    }
  }

  ._vip_benefit_list {
    padding: 0.2rem 0.3rem;

    ._item {
      margin-bottom: 0.4rem;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .show_title {
    text-align: center;
    font-size: 0.35rem;
    color: #595757;
  }
}
._right_bar_new_card{
  position: fixed;
  top: 0.3rem;
  right: 0rem;
  img {
    height: 2.7rem;
  }
}
</style>
